import * as React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import NotFoundComponents from '../components/not-found/NotFound'
import NotFoundIcon from '../components/not-found/NotFoundIcon'

// markup
const NotFoundPage = ({ data }) => {
	return (
		<NotFoundComponents
			title='Halaman tidak ditemukan'
			subtitle='Mohon maaf Juragan! Halaman ini bukan halaman yang anda cari. Gunakan alat pencarian diatas, atau kunjungi website kami:'
			image={getImage(data.notFoundBanner)}
		>
			<NotFoundIcon
				image={getImage(data.notFoundIcons.nodes[0])}
				link='/'
			/>
			<NotFoundIcon
				image={getImage(data.notFoundIcons.nodes[1])}
				link='/'
			/>
			<NotFoundIcon
				image={getImage(data.notFoundIcons.nodes[2])}
				width={true}
				link='/'
			/>
			<NotFoundIcon
				image={getImage(data.notFoundIcons.nodes[3])}
				width={true}
				link='/'
			/>
		</NotFoundComponents>
	)
}

export default NotFoundPage

export const queryData = graphql`
	{
		notFoundBanner: file(
			relativePath: { eq: "banners/not-found/wpg-not-found-01.png" }
		) {
			childImageSharp {
				gatsbyImageData(width: 1920)
			}
		}
		notFoundIcons: allFile(
			filter: { relativeDirectory: { eq: "icons/not-found-icon" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 500)
				}
			}
		}
	}
`
