import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// markup
const NotFoundPageIcon = ({ image, link, width }) => {
	return (
		<a href={link} className={`${width ? 'width' : ''}`}>
			<GatsbyImage image={image} alt='wpg-icon' />
		</a>
	)
}

export default NotFoundPageIcon
