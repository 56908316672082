import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import searchIcon from '../../images/icons/search-icon.svg'

import '../../styles/reset.scss'
import '../../styles/global.scss'
import '../../styles/main.scss'

const pageStyles = {
	width: '100%',
	color: '#232129',
	padding: '96px',
	fontFamily: '-apple-system, Roboto, sans-serif, serif',
}
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
}

const paragraphStyles = {
	marginBottom: 48,
}
const codeStyles = {
	color: '#8A6534',
	padding: 4,
	backgroundColor: '#FFF4DB',
	fontSize: '1.25rem',
	borderRadius: 4,
}

// markup
const NotFoundPage = ({ title, subtitle, image, children }) => {
	return (
		<main className='apl-notfound'>
			<div className='apl-notfound--background is-hidden-touch'>
				<GatsbyImage image={image} alt='wpg-banner' />
			</div>
			<div className='apl-notfound--content is-hidden-touch'>
				<div className='apl-notfound--searchbar'>
					<img src={searchIcon} alt='search-icon' />
					<input
						type='text'
						placeholder='Voucher Promo Pintar Andalan'
					/>
				</div>
				<h2>404</h2>
				<h1>{title}</h1>
				<p>{subtitle}</p>
				<div className='apl-notfound--icon'>{children}</div>
			</div>
			<div style={pageStyles} className='is-hidden-desktop'>
				<title>Not found</title>
				<h1 style={headingStyles}>Page not found</h1>
				<p style={paragraphStyles}>
					Sorry{' '}
					<span role='img' aria-label='Pensive emoji'>
						😔
					</span>{' '}
					we couldn’t find what you were looking for.
					<br />
					{process.env.NODE_ENV === 'development' ? (
						<>
							<br />
							Try creating a page in{' '}
							<code style={codeStyles}>src/pages/</code>.
							<br />
						</>
					) : null}
					<br />
					<Link to='/'>Go home</Link>.
				</p>
			</div>
		</main>
	)
}

export default NotFoundPage
